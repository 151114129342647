module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Covesmart","icon":"src/favicon.png","short_name":"Cove","start_url":"/","background_color":"#ffffff","theme_color":"#00b29c","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"64379b9dbf60bd4584e3c0185cbcac27"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"11d6a65a40e06831ac0cf98db1d57a18","enableOnDevMode":true,"mixpanelConfig":{"api_host":"https://agyzx.covesmart.com"},"pageViews":"all","trackPageViewsAs":"page_view"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","es"],"defaultLanguage":"en","siteUrl":"https://www.covesmart.com","i18nextOptions":{"debug":false,"defaultNS":"common","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"react":{"useSuspense":false},"keySeparator":".","nsSeparator":":"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
